<template>
  <view-item title="应付款统计">
    <template #operation>
      <en-button type="primary" @click="operation.add.click">查看总计</en-button>
      <en-button type="primary" @click="operation.export.click">导出</en-button>
    </template>
    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <table-dynamic
            :data="table.data"
            :method="table.get"
            :paging="table.paging"
            :height="height - 55"
            pagination
            :loading="table.loading"
            code="CUSPABFD"
            :config="table.config"
          >
            <template #OPERATION="{ row }: { row: EnocloudSettlementDefinitions['PayableReceivableStatisticsDto'], $index: number }">
              <en-button type="primary" link @click="operation.exports.click()">导出明细</en-button>
            </template>
            <template #CUSTOMER_NAME="{ row }: { row: EnocloudSettlementDefinitions['PayableReceivableStatisticsDto'] }">
              <en-button type="primary" link @click="operation.name.click(row)">{{ row.customer?.name }}</en-button>
            </template>
          </table-dynamic>
        </template>
      </flex-box>
    </en-card>
  </view-item>
  <en-drawer v-model="detail.visible" title="查看总计" size="20%">
    <div v-for="item in detail.table.data" class="flex flex-col gap-7">
      <div class="flex flex-col gap-4">
        <div style="color: #4c58d9ff">期初金额：:{{ formatMoney(item.initialAmount) }}</div>
        <div style="color: #4c58d9ff">本期应付金额：:{{ formatMoney(item.currentAmount) }}</div>
        <div style="color: #4c58d9ff">本期已付金额：:{{ formatMoney(Math.abs(item.currentDiscountAmount)) }}</div>
        <div style="color: #4c58d9ff">期末应付金额：:{{ formatMoney(item.currentBalanceAmount) }}</div>
      </div>
    </div>
    <template #footer>
      <en-button type="primary" @click="detail.footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="detail.footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>

  <en-dialog v-model="customerDetail.visible" title="应付款明细统计">
    <en-table :data="customerDetail.table.data" :loading="customerDetail.table.loading">
      <en-table-column label="往来单位" prop="customer.name"></en-table-column>
      <en-table-column label="业务单号" prop="serialNo"></en-table-column>
      <en-table-column label="单据类型" prop="documentType.message"></en-table-column>
      <en-table-column label="日期">
        <template #default="{ row }: { row: EnocloudSettlementDefinitions['PayableReceivableStatisticsDto'] }">
          {{ formatDate(row.datetime) }}
        </template>
      </en-table-column>
      <en-table-column label="产生金额" prop="amount">
        <template #default="{ row }: { row: EnocloudSettlementDefinitions['PayableReceivableStatisticsDto'] }">
          {{ formatMoney(row.amount) }}
        </template>
      </en-table-column>
    </en-table>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        children: {
          add: {
           async click() {
             await this.detail.table.get()
              this.detail.visible = true
            }
          },
          name: {
            click(row) {
              this.customerDetail.form.data.customerId = row?.customer?.id
              this.customerDetail.visible = true
              this.customerDetail.table.get()
            }
          },
          export: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/statistics/export/account/:type',
                params(params) {
                  params.paths = ['PAB']
                }
              }
            },
            async click() {
              await this.operation.export.get()
              this.store.openDownload()
            }
          },
          exports: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/statistics/export/account/:type/details',
                params(params) {
                  params.paths = ['PAB']
                }
              }
            },
            async click() {
              await this.operation.exports.get()
              this.store.openDownload()
            }
          }
        }
      },
      form: {
        data: {
          startDate: '',
          endDate: '',
          customerId: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/statistics/account/:type',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.paths = ['PAB']
              params.payload = this.form.data
            }
          }
        },

        config: {
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, rsexp: 'id,name', pagingEnabled: false })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },

          CUSTOMER_CONTACT_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'contactName'
              }
            }
          },
          CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'contactCellphone'
              }
            }
          },

          CUSTOMER_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'unitType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['CUSTTYPE'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/statistics/account/summary/:type',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['PAB']
              params.payload = this.form.data
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              click() {
                this.detail.visible = false
              }
            }
          }
        }
      },
      customerDetail: {
        visible: false,
        children: {
          form: {
            data: {
              customerId: '',
              startDate: '',
              endDate: ''
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/settlement/statistics/account/:type/details',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['PAB']
                  params.payload = this.customerDetail.form.data
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              click() {
                this.detail.visible = false
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
 
  }
})
</script>
